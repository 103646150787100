import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Box from 'components/box';
import Title from 'components/title';
import Head from 'components/head';

const About = ({ data }) => (
  <>
    <Box>
      <Title as="h2" size="large">
        {data.aboutJson.teaser.childMarkdownRemark.rawMarkdownBody}
      </Title>
    </Box>
    <Head pageTitle={data.aboutJson.title} />
    <Box>
      <div
        dangerouslySetInnerHTML={{
          __html: data.aboutJson.content.childMarkdownRemark.html,
        }}
      />
    </Box>
  </>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query AboutQuery {
    aboutJson {
      title
      teaser {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
  }
`;
